import { render, staticRenderFns } from "./PartnerDetails.vue?vue&type=template&id=dbc1fa8c&scoped=true&"
import script from "./PartnerDetails.ts?vue&type=script&lang=js&"
export * from "./PartnerDetails.ts?vue&type=script&lang=js&"
import style0 from "./partner-details.scss?vue&type=style&index=0&id=dbc1fa8c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dbc1fa8c",
  null
  
)

export default component.exports