import Vue from 'vue';
import { onBeforeMount, reactive, ref, watch } from '@vue/composition-api';
import dayjs from 'dayjs';
import * as XLSX from 'xlsx';
import ErrorModel from '@/api/models/ErrorModel';
import {
  DateRangeEnum,
  PurchaseConfirmModalStateInterface,
  ThemeActionResponseMessageEnum,
  ThemeConfirmModalDescriptionEnum,
  ThemeConfirmModalTitleEnum,
  ThemeManagementNumericSelectListInterface,
  ThemeManagementNumericStringItem,
  ThemeManagementNumericValueItem,
  ThemeManagementStringSelectListInterface,
  ThemesManagementConfirmModalActionsEnum,
  ThemesManagementFilterParamsInterface,
  ThemesManagementFilterStateInterface,
  ThemesManagementPaymentStatusEnum,
} from '../types/themes-management.type';
import { getAllPurchases, updateThemePurchasePaymentStatus } from '../api/themes-management';
import { PurchaseInterface, PurchaseStatusEnum } from '../models/ThemesFinanceResponseModel';

const useThemesManagement = () => {
  const pageSizeList = [];
  const paymentStatusList = Object.values(ThemesManagementPaymentStatusEnum).map((status) => {
    return { label: `${status[0].toLocaleUpperCase()}${status.slice(1)}`, value: status };
  });

  const totalPages = ref(0);
  const bulkRefundable = ref(false);
  const bulkSettlementAllowed = ref(false);
  const isAllSelected = ref(false);
  const isThemesListLoading = ref(false);

  const themesManagementFilterState = reactive<ThemesManagementFilterStateInterface>({
    searchValue: '',
    selectedResultPerPage: {
      label: `10`,
      value: `10`,
    },
    selectedTheme: {
      label: '',
      value: '',
    },
    selectedPartner: {
      label: '',
      value: 0,
    },
    paymentStatus: {
      label: ThemesManagementPaymentStatusEnum.All,
      value: ThemesManagementPaymentStatusEnum.All,
    },
    dateRange: {
      startDate: '',
      endDate: '',
    },
    targetPage: 1,
  });
  const purchasesRecordList = reactive<Record<string, PurchaseInterface[]>>({ list: [] });
  const selectedPurchases = reactive<Record<string, PurchaseInterface[]>>({ list: [] });
  const partnersSelectList = reactive<ThemeManagementNumericSelectListInterface>({ list: [] });
  const themesSelectList = reactive<ThemeManagementStringSelectListInterface>({ list: [] });
  const confirmModalState = reactive<PurchaseConfirmModalStateInterface>({
    isOpen: false,
    isLoading: false,
    title: '',
    description: '',
    type: null,
    id: null,
    isBulk: false,
  });

  for (let index = 10; index <= 100; index += 10) {
    pageSizeList.push({
      label: `${index}`,
      value: `${index}`,
    });
  }

  onBeforeMount(async () => {
    await loadPurchasesList();
  });

  watch(
    selectedPurchases,
    () => {
      bulkRefundable.value = canBulkRefund();
      bulkSettlementAllowed.value = canBulkSettle();
      isAllSelected.value = isAllSelectedCheck();
    },
    { deep: true },
  );

  watch(themesManagementFilterState, async () => await loadPurchasesList(), { deep: true });

  const loadPurchasesList = async () => {
    selectedPurchases.list = [];
    purchasesRecordList.list = [];
    isThemesListLoading.value = true;

    const params: ThemesManagementFilterParamsInterface = {
      search: themesManagementFilterState.searchValue,
      theme: themesManagementFilterState.selectedTheme.value,
      partner: themesManagementFilterState.selectedPartner.value,
      status: themesManagementFilterState.paymentStatus.value,
      purchased_from: themesManagementFilterState.dateRange.startDate,
      purchased_to: themesManagementFilterState.dateRange.endDate,
      per_page: themesManagementFilterState.selectedResultPerPage.value,
      page: themesManagementFilterState.targetPage,
    };
    const allThemesResponse = await getAllPurchases(params);

    if (allThemesResponse instanceof ErrorModel || !allThemesResponse.payload) {
      Vue.$toast.error('Could not load themes list');
      isThemesListLoading.value = false;
      return;
    }
    const payload = allThemesResponse.payload;
    purchasesRecordList.list = [...payload.purchases];
    partnersSelectList.list = payload.partners.map((partner) => {
      return { label: partner.name, value: partner.id };
    });
    themesSelectList.list = payload.themes.map((themes) => {
      return { value: themes.id, label: themes.name };
    });
    totalPages.value = payload.pagination.last_page;
    isThemesListLoading.value = false;
  };

  const onPartnerChange = (partner: ThemeManagementNumericValueItem) => {
    themesManagementFilterState.selectedPartner = partner;
    themesManagementFilterState.selectedTheme = {
      value: '',
      label: '',
    };
    resetTargetPage();
  };

  const onPaymentStatusChange = (status: Record<string, ThemesManagementPaymentStatusEnum>) => {
    themesManagementFilterState.paymentStatus = status;
    resetTargetPage();
  };

  const onThemeChange = (theme: ThemeManagementNumericStringItem) => {
    themesManagementFilterState.selectedTheme = theme;
    resetTargetPage();
  };

  const onDateValueChange = (changedDate: Record<string, string>, dateField: DateRangeEnum) => {
    let dateValue = changedDate.value;
    if (dateValue) {
      dateValue = dayjs(changedDate.value).format('YYYY-MM-DD');
    }
    themesManagementFilterState.dateRange[dateField] = dateValue;
    resetTargetPage();
  };

  const onResultPerPageChange = (perPage: Record<string, string>) => {
    themesManagementFilterState.selectedResultPerPage = perPage;
    resetTargetPage();
  };

  const resetTargetPage = () => (themesManagementFilterState.targetPage = 1);

  const resetConfirmModalState = () => {
    confirmModalState.isOpen = false;
    confirmModalState.isLoading = false;
    confirmModalState.title = '';
    confirmModalState.description = '';
    confirmModalState.type = null;
    confirmModalState.id = null;
    confirmModalState.isBulk = false;
  };

  const onPurchaseItemAction = (
    id: number | number[],
    action: ThemesManagementConfirmModalActionsEnum,
    isBulk: boolean,
  ) => {
    confirmModalState.isOpen = true;
    confirmModalState.type = action;
    confirmModalState.id = id;
    confirmModalState.isBulk = isBulk;
    confirmModalState.title =
      action === ThemesManagementConfirmModalActionsEnum.Settle
        ? ThemeConfirmModalTitleEnum.Settle
        : ThemeConfirmModalTitleEnum.Refund;
    confirmModalState.description = getConfirmModalDescription(action, isBulk);
  };

  const onRefundItemClick = (id: number, isBulk: boolean) => {
    onPurchaseItemAction(id, ThemesManagementConfirmModalActionsEnum.Refund, isBulk);
  };

  const onSettleItemClick = (id: number, isBulk: boolean) => {
    onPurchaseItemAction(id, ThemesManagementConfirmModalActionsEnum.Settle, isBulk);
  };

  const commitConfirmModalAction = async () => {
    confirmModalState.isLoading = true;
    if (confirmModalState.isBulk) {
      const canPerformAction =
        confirmModalState.type === ThemesManagementConfirmModalActionsEnum.Settle ? canBulkSettle() : canBulkRefund();
      if (!canPerformAction) {
        Vue.$toast.error(`This bulk action cannot be done on your current selection.`);
        resetConfirmModalState();
        return;
      }
    }

    if (confirmModalState.id) {
      const response = await updateThemePurchasePaymentStatus(
        Array.isArray(confirmModalState.id) ? confirmModalState.id : [confirmModalState.id],
        confirmModalState.type,
      );
      if (response instanceof ErrorModel || response.status !== 'success') {
        Vue.$toast.error(`Couldn't complete your request!`);
        resetConfirmModalState();
        return;
      }
      const successMessage =
        confirmModalState.type === ThemesManagementConfirmModalActionsEnum.Settle
          ? ThemeActionResponseMessageEnum.SettleSuccess
          : ThemeActionResponseMessageEnum.RefundSuccess;
      await loadPurchasesList();
      resetConfirmModalState();
      Vue.$toast.success(successMessage);
    }
  };

  const getSelectedIds = () => {
    return selectedPurchases.list.map((theme: PurchaseInterface) => theme.id);
  };

  const isThemeRefundable = (status: PurchaseStatusEnum) => status == PurchaseStatusEnum.Pending;

  const isSelectedPurchase = (id: number) => {
    return selectedPurchases.list.map((theme: PurchaseInterface) => theme.id).indexOf(id) !== -1;
  };

  const deselectPurchase = (id: number) => {
    selectedPurchases.list = selectedPurchases.list.filter((purchase: PurchaseInterface) => {
      return purchase.id != id;
    });
  };

  const getPurchaseById = (id: number) =>
    purchasesRecordList.list.find((purchase: PurchaseInterface) => purchase.id === id) || null;

  const onCheckboxAction = (isChecked: boolean, id: number) => {
    if (!isChecked) {
      deselectPurchase(id);
      return;
    }
    if (isSelectedPurchase(id)) {
      return;
    }
    const theme = getPurchaseById(id);
    if (!theme) {
      return;
    }
    selectedPurchases.list.push(theme);
  };

  const canBulkRefund = () => {
    if (!selectedPurchases.list.length) {
      return false;
    }
    const notRefundable =
      selectedPurchases.list.find((purchase: PurchaseInterface) => purchase.status !== PurchaseStatusEnum.Pending) ||
      null;
    return notRefundable === null;
  };

  const canBulkSettle = () => {
    if (!selectedPurchases.list.length) {
      return false;
    }
    const notPending =
      selectedPurchases.list.find((purchase: PurchaseInterface) => purchase.status !== PurchaseStatusEnum.Pending) ||
      null;
    return notPending === null;
  };

  const toggleSelectAll = (event: Record<any, any>) => {
    if (event.isChecked) {
      selectedPurchases.list = [...purchasesRecordList.list];
      return;
    }
    selectedPurchases.list = [];
  };

  const exportThemes = () => {
    if (!selectedPurchases.list.length) {
      Vue.$toast.error('No selected rows to export!');
      return;
    }
    const worksheet = XLSX.utils.json_to_sheet(selectedPurchases.list);
    const new_workbook = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(new_workbook, worksheet, 'theme-purchases');
    XLSX.writeFile(new_workbook, `selected-themes-purchases-${dayjs().format('DD-MM-YYYY')}.xlsx`);
  };

  const isAllSelectedCheck = () => {
    return selectedPurchases.list.length > 0 && purchasesRecordList.list.length === selectedPurchases.list.length;
  };

  const getConfirmModalDescription = (action: ThemesManagementConfirmModalActionsEnum, isBulk: boolean) => {
    let description =
      action === ThemesManagementConfirmModalActionsEnum.Settle
        ? ThemeConfirmModalDescriptionEnum.Settle
        : ThemeConfirmModalDescriptionEnum.Refund;

    if (!isBulk) {
      return description;
    }

    description =
      action === ThemesManagementConfirmModalActionsEnum.Settle
        ? ThemeConfirmModalDescriptionEnum.SettleBulk
        : ThemeConfirmModalDescriptionEnum.RefundBulk;

    return description;
  };

  return {
    purchasesRecordList,
    partnersSelectList,
    themesSelectList,
    paymentStatusList,
    isThemesListLoading,
    selectedPurchases,
    pageSizeList,
    themesManagementFilterState,
    totalPages,
    confirmModalState,
    ThemesManagementConfirmModalActionsEnum,
    ThemesManagementPaymentStatusEnum,
    PurchaseStatusEnum,
    bulkSettlementAllowed,
    bulkRefundable,
    isAllSelected,
    DateRangeEnum,
    onPartnerChange,
    onPaymentStatusChange,
    onThemeChange,
    onDateValueChange,
    onResultPerPageChange,
    resetTargetPage,
    resetConfirmModalState,
    isThemeRefundable,
    commitConfirmModalAction,
    onRefundItemClick,
    onSettleItemClick,
    onCheckboxAction,
    isSelectedPurchase,
    toggleSelectAll,
    exportThemes,
    getSelectedIds,
  };
};

export default useThemesManagement;
