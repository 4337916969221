import BaseModel from '@/api/models/BaseModel';
import { LengthAwarePaginationInterface } from '@/types/global-types/pagination.type';

class ThemesFinanceResponseModel extends BaseModel {
  public payload: {
    purchases: PurchaseInterface[];
    themes: ThemeInterface[];
    partners: PartnerInterface[];
    pagination: LengthAwarePaginationInterface;
  };

  constructor(args: any) {
    super(args);
    this.payload = args.payload;
  }
}

export default ThemesFinanceResponseModel;

export interface PurchaseInterface {
  id: number;
  store_id: number;
  store_url: string;
  partner_name: string;
  theme_name: string;
  theme_name_ar: string;
  theme_price: number | null;
  partner_share: number | null;
  zid_share: number | null;
  tax_paid: number | null;
  total_amount_paid: number | null;
  purchased_at: string;
  status: PurchaseStatusEnum;
}

export interface ThemeInterface {
  id: string;
  name: string;
}

export interface PartnerInterface {
  id: number;
  name: string;
}

export enum PurchaseStatusEnum {
  Pending = 'pending',
  Settled = 'settled',
  Refunded = 'refunded',
}
