import httpClient from '@/api/httpClient';
import FinancePartnersManagementResponseModel from '@/domains/finance-admin/models/FinancePartnersManagementResponseModel';
import ErrorModel from '@/api/models/ErrorModel';
import {
  FinancePartnersManagementFilterParamsInterface,
  FinancePartnersManagementFilterParamsType,
} from '@/domains/finance-admin/types/partners-management.type';

const ENDPOINT_URL = '/market';

export const getAllPartners = (
  params: FinancePartnersManagementFilterParamsType,
): Promise<FinancePartnersManagementResponseModel | ErrorModel> => {
  const searchParams: FinancePartnersManagementFilterParamsInterface = {};
  if (params.search) {
    searchParams.search = params.search;
  }
  if (params.per_page) {
    searchParams.per_page = params.per_page;
  }
  if (params.page) {
    searchParams.page = params.page;
  }
  if (params.order_by) {
    searchParams.order_by = params.order_by;
  }
  if (params.order_dir) {
    searchParams.order_dir = params.order_dir;
  }
  return httpClient
    .get(`${ENDPOINT_URL}/finance-admin/partners`, { params: { ...searchParams } })
    .then((response) => new FinancePartnersManagementResponseModel(response))
    .catch((error) => error);
};
