import BaseModel from '../../../api/models/BaseModel';
import {
  AppType,
  PartnerBankAccountType,
  PartnershipPartnerThemeType,
  PartnershipPartnerType,
} from '../types/partnerDetails.type';
import { PartnershipContractType } from '../../partnership-details/types/partnership.type';
import { ThemeType } from '@/domains/finance-admin/types/partner-details.types';

class PartnerDetailsResponseModel extends BaseModel {
  public partner_contract: {
    partner: PartnershipPartnerType;
    apps: AppType[];
    themes: PartnershipPartnerThemeType[];
    partnership_details: PartnershipContractType;
    partner_bank_account: PartnerBankAccountType;
  };
  constructor(arg: any) {
    super(arg);
    this.partner_contract = arg.partner_contract;
  }
}

export default PartnerDetailsResponseModel;
