export enum ThemesManagementPaymentStatusEnum {
  All = 'all',
  Pending = 'pending',
  Settled = 'settled',
  Refunded = 'refunded',
}

export type ThemeManagementNumericValueItem = {
  label: string;
  value: number;
};

export type ThemeManagementNumericStringItem = {
  label: string;
  value: string;
};

export interface ThemeManagementNumericSelectListInterface {
  list: ThemeManagementNumericValueItem[];
}

export type ThemeManagementStringSelectListInterface = {
  list: ThemeManagementNumericStringItem[];
};

export interface DateRangeInterface {
  startDate: string;
  endDate: string;
}

export interface ThemesManagementFilterStateInterface {
  searchValue: string;
  selectedResultPerPage: Record<string, string>;
  selectedTheme: ThemeManagementNumericStringItem;
  selectedPartner: ThemeManagementNumericValueItem;
  paymentStatus: Record<string, ThemesManagementPaymentStatusEnum>;
  dateRange: DateRangeInterface;
  targetPage: number;
}

export enum ThemesManagementConfirmModalActionsEnum {
  Refund = 'refunded',
  Settle = 'settled',
}

export interface PurchaseConfirmModalStateInterface {
  id: number | number[] | null;
  isOpen: boolean;
  isLoading: boolean;
  title: string;
  description: string;
  type: ThemesManagementConfirmModalActionsEnum | null;
  isBulk: boolean;
}
export interface ThemesManagementFilterParamsInterface {
  theme?: string;
  partner?: number;
  search?: string;
  status?: ThemesManagementPaymentStatusEnum;
  purchased_from?: string;
  purchased_to?: string;
  per_page?: string;
  page?: number;
}

export enum DateRangeEnum {
  StartDate = 'startDate',
  EndDate = 'endDate',
}

export enum ThemeConfirmModalTitleEnum {
  Refund = 'Are you sure about making a refund?',
  Settle = 'Are you sure about making a settlement?',
}

export enum ThemeConfirmModalDescriptionEnum {
  Refund = 'You are about to make a refund',
  Settle = 'You are about to make a settlement',
  RefundBulk = 'You are about to make a bulk refund to all the selected rows',
  SettleBulk = 'Your are about to make a bulk settlement to all the selected rows',
}

export enum ThemeActionResponseMessageEnum {
  RefundSuccess = 'Your refund request was completed successfully',
  SettleSuccess = 'Your settlement request was completed successfully',
}
