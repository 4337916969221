import Vue from 'vue';
import { onBeforeMount, reactive, ref, watch } from '@vue/composition-api';
import {
  FinancePartnersManagementFiltersState,
  FinancePartnersManagementNumericFieldType,
  FinancePartnersManagementOrderByEnum,
  FinancePartnersManagementOrderDirEnum,
  FinancePartnersListType,
} from '@/domains/finance-admin/types/partners-management.type';
import { getAllPartners } from '@/domains/finance-admin/api/partners-management';
import ErrorModel from '@/api/models/ErrorModel';
import router from '@/router';
import { RoutingRouteEnum } from '@/router/routes.enum';
import FinancePartnersManagementResponseModel from '@/domains/finance-admin/models/FinancePartnersManagementResponseModel';

const usePartnersManagement = () => {
  const pageSizeList: FinancePartnersManagementNumericFieldType[] = [];
  for (let index = 10; index <= 100; index += 10) {
    pageSizeList.push({
      label: `${index}`,
      value: index,
    });
  }
  const totalPages = ref(0);
  const isPartnersLoading = ref(false);
  const partnersFiltersState = reactive<FinancePartnersManagementFiltersState>({
    search: '',
    resultPerPage: { label: '10', value: 10 },
    targetPage: 1,
    orderBy: FinancePartnersManagementOrderByEnum.TotalApps,
    orderDir: FinancePartnersManagementOrderDirEnum.Descending,
  });
  const partnersList = reactive<FinancePartnersListType>({ list: [] });
  onBeforeMount(async () => {
    await loadPartnersList();
  });

  watch(partnersFiltersState, async () => await loadPartnersList(), { deep: true });

  const loadPartnersList = async () => {
    isPartnersLoading.value = true;
    partnersList.list = [];
    const params = {
      search: partnersFiltersState.search,
      per_page: partnersFiltersState.resultPerPage.value,
      page: partnersFiltersState.targetPage,
      order_by: partnersFiltersState.orderBy,
      order_dir: partnersFiltersState.orderDir,
    };
    const response = await getAllPartners(params);
    if (response instanceof ErrorModel || !(<FinancePartnersManagementResponseModel>response).partners) {
      Vue.$toast.error('Cannot Load partners List.');
      isPartnersLoading.value = false;
      return;
    }
    partnersList.list = response.partners;
    totalPages.value = response.pagination.last_page;
    isPartnersLoading.value = false;
  };

  const resetTargetPage = () => {
    partnersFiltersState.targetPage = 1;
  };

  const onPerPageChange = (event: FinancePartnersManagementNumericFieldType) => {
    partnersFiltersState.resultPerPage = event;
    resetTargetPage();
  };

  const onSearchValueChange = (value: string) => {
    partnersFiltersState.search = value;
    resetTargetPage();
  };

  const onTargetPageChange = (page: number) => {
    partnersFiltersState.targetPage = page;
  };

  const onOrderByClick = (
    orderBy: FinancePartnersManagementOrderByEnum,
    defaultOrderDir: FinancePartnersManagementOrderDirEnum,
  ) => {
    if (partnersFiltersState.orderBy === orderBy && partnersFiltersState.orderDir === defaultOrderDir) {
      partnersFiltersState.orderDir =
        partnersFiltersState.orderDir === FinancePartnersManagementOrderDirEnum.Ascending
          ? FinancePartnersManagementOrderDirEnum.Descending
          : FinancePartnersManagementOrderDirEnum.Ascending;
    } else {
      partnersFiltersState.orderBy = orderBy;
      partnersFiltersState.orderDir = defaultOrderDir;
    }
    resetTargetPage();
  };

  const isOrderedBy = (
    column: FinancePartnersManagementOrderByEnum,
    orderDir: FinancePartnersManagementOrderDirEnum,
  ) => {
    return partnersFiltersState.orderBy === column && partnersFiltersState.orderDir === orderDir;
  };

  const isOrderedByTotalApps = (orderDir: FinancePartnersManagementOrderDirEnum) => {
    return isOrderedBy(FinancePartnersManagementOrderByEnum.TotalApps, orderDir);
  };

  const isOrderedByAppInstalls = (orderDir: FinancePartnersManagementOrderDirEnum) => {
    return isOrderedBy(FinancePartnersManagementOrderByEnum.AppInstalls, orderDir);
  };

  const onViewPartnerClick = (partnerId: number) => {
    router
      .push({
        name: RoutingRouteEnum.FinanceAdmin_PartnerDetails,
        params: { id: partnerId.toString() },
      })
      .catch((err) => {
        /**/
      });
  };
  return {
    pageSizeList,
    totalPages,
    isPartnersLoading,
    partnersList,
    partnersFiltersState,
    FinancePartnersManagementOrderByEnum,
    FinancePartnersManagementOrderDirEnum,
    onPerPageChange,
    onSearchValueChange,
    onTargetPageChange,
    onOrderByClick,
    isOrderedByTotalApps,
    isOrderedByAppInstalls,
    onViewPartnerClick,
  };
};

export default usePartnersManagement;
