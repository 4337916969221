<template>
  <a
    :href="value"
    target="_blank"
    rel="noreferrer noopener"
    class="flex items-center flex-col w-8/12 rounded-xl bg-white-pureWhite p-3 mx-auto hover:text-primary"
    download
  >
    <span class="flex items-center gap-x-2">
      <base-icon width="20" height="23" view-box="0 0 23 20">
        <pdf-icon />
      </base-icon>
      <base-text text-style="p3">{{ label }}</base-text>
    </span>
    <base-text textStyle="p2">Download File</base-text>
  </a>
</template>

<script>
import BaseText from '@/components/text/BaseText.vue';
import BaseIcon from '@/components/base-icon/BaseIcon.vue';
import PdfIcon from '@/assets/icons/domains/finance-admin/PdfIcon.vue';
export default {
  name: 'FileDownloadLink',
  components: {
    BaseText,
    BaseIcon,
    PdfIcon,
  },
  props: {
    label: String,
    value: String,
  },
};
</script>
