import httpClient from '@/api/httpClient';
import PartnershipInfoModel from '../../models/PartnershipInfoModel';
import { PartnershipInfoProps } from '../../../domains/partnership-details/types/partnership.type';
import PartnershipResponseModel from '../../../domains/partnership-details/models/PartnershipResponseModel';
import PartnerDetailsResponseModel from '@/domains/partnerships-admin/models/PartnerDetailsResponseModel';

const ENDPOINT_URL = '/market';

export const setPartnershipDataToApi = (partnershipInfo: PartnershipInfoModel) => {
  const partnershipData = new FormData();
  if (partnershipInfo.id) partnershipData.append(PartnershipInfoProps.Id, partnershipInfo.id);
  partnershipData.append(PartnershipInfoProps.Partnership_officer_name, partnershipInfo.partnership_officer_name);
  partnershipData.append(PartnershipInfoProps.Company_website, partnershipInfo.company_website);
  partnershipData.append(PartnershipInfoProps.Company_name, partnershipInfo.company_name);
  partnershipData.append(PartnershipInfoProps.Company_work_field_type, `${partnershipInfo.company_work_field_type}`);
  partnershipData.append(PartnershipInfoProps.Company_work_field, `${partnershipInfo.company_work_field}`);
  partnershipData.append(PartnershipInfoProps.Customer_count, `${partnershipInfo.customer_count}`);
  partnershipData.append(PartnershipInfoProps.Language, partnershipInfo.language);
  partnershipData.append(PartnershipInfoProps.Partner_id, `${partnershipInfo.partner_id}`);
  partnershipData.append(PartnershipInfoProps.Partnership_officer_email, partnershipInfo.partnership_officer_email);
  partnershipData.append(PartnershipInfoProps.Partnership_officer_mobile, partnershipInfo.partnership_officer_mobile);
  partnershipData.append(PartnershipInfoProps.Commercial_register_doc, partnershipInfo.commercial_register_doc);
  partnershipData.append(PartnershipInfoProps.Company_profile_doc, partnershipInfo.company_profile_doc ?? '');
  partnershipData.append(PartnershipInfoProps.Vat_certificate_doc, partnershipInfo.vat_certificate_doc ?? '');
  partnershipData.append(PartnershipInfoProps.Service_pricing_doc, partnershipInfo.service_pricing_doc ?? '');
  partnershipData.append(
    PartnershipInfoProps.Communications_license_doc,
    partnershipInfo.communications_license_doc ?? '',
  );

  return httpClient
    .post(`${ENDPOINT_URL}/partnership-details`, partnershipData)
    .then((response) => new PartnershipResponseModel(response))
    .catch((error) => error);
};

export const getPartnershipData = () => {
  return httpClient
    .get(`${ENDPOINT_URL}/partnership-details`)
    .then((response) => new PartnershipResponseModel(response))
    .catch((error) => error);
};

export const getPartnershipDetails = (partnerID: number) => {
  return httpClient
    .get(`${ENDPOINT_URL}/partnership-admin/${partnerID}`)
    .then((response) => new PartnerDetailsResponseModel(response))
    .catch((error) => error);
};
