import { RoutingRouteEnum } from '@/router/routes.enum';
import { FinancePartnersManagementNumericFieldType } from './partners-management.type';
export { FinancePartnersManagementNumericFieldType } from './partners-management.type';
export {
  ApplicationListingStatusProperty as ApplicationListingStatusEnum,
  ApplicationTypeProperty as ApplicationTypeEnum,
} from '@/api/models/ApplicationModel';

export enum PartnersManagementRoutesEnum {
  PartnersManagement = RoutingRouteEnum.FinanceAdmin_PartnersManagement,
  PartnerDetails = RoutingRouteEnum.FinanceAdmin_PartnerDetails,
}

export enum ContractStatusEnum {
  Signed = 'SIGNED',
  Unsigned = 'UNSIGNED',
}

export enum RevenueModelEnum {
  FixedPrice = 'Fixed price',
  PercentageOfRevenue = 'Percentage of revenue',
  Slap = 'Slap Model',
}

export enum RevenueModelValueEnum {
  FixedPrice = 1,
  PercentageOfRevenue = 2,
  Slap = 3,
}

export enum WorkFieldEnum {
  DeliveryOrStorage = 'Delivery/Storage Companies',
  TechnologySolutions = 'Technology Solutions Companies',
}

export enum WorkFieldValueEnum {
  DeliveryOrStorage = 1,
  TechnologySolutions = 1,
}

export enum WorkSubFieldEnum {
  All = 'All',
  AccountingSoftwareProvider = 'Accounting Software Provider',
  ResourceManagementSoftwareProvider = 'Resource Management Software Provider',
  ChatCommunicationSolutionsProvider = 'Chat & Communication Solutions Provider',
  DedicatedApplicationsForZidMerchants = 'Dedicated Applications For Zid Merchants',
  OtherSolutions = 'Other Solutions',
  LastMileDelivery = 'Last Mile Delivery Company',
  StorageAndOrderProcessing = 'Storage And Order Processing Company',
}

export enum WorkSubFieldValueEnum {
  LastMileDelivery = 1,
  StorageAndOrderProcessing = 2,
  All = 3,
  AccountingSoftwareProvider = 4,
  ResourceManagementSoftwareProvider = 5,
  ChatCommunicationSolutionsProvider = 6,
  DedicatedApplicationsForZidMerchants = 7,
  OtherSolutions = 8,
}
export enum PotentialCustomersLabelEnum {
  '0-50' = '0-50',
  '51-100' = '51-100',
  '101-500' = '101-500',
  MoreThan500 = 'More Than 500',
}

export enum PotentialCustomersValueEnum {
  '0-50' = 50,
  '51-100' = 100,
  '101-500' = 500,
  MoreThan500 = 501,
}

export enum ApplicationTypeLabelEnum {
  OAuth = 'OAuth',
  Javascript = 'Pure Javascript',
  Shipping = 'Shipping App',
}

export enum ApplicationListingStatusLabelEnum {
  Draft = 'Draft',
  RequestToPublish = 'Request to publish',
  Rejected = 'Rejected',
  Published = 'Published',
  Unpublished = 'Unpublished',
}

export type FinancePartnerDetailsDropdownAppType = {
  list: FinancePartnersManagementNumericFieldType[];
};

export type FinancePartnerDetailsDropdownThemeType = {
  list: FinancePartnersManagementNumericFieldType[];
};

export type AppListType = {
  list: AppType[];
};

export type AppType = {
  app_id: number;
  partner_id: number;
  app_name: string;
  status: number;
  type: number;
  icon: string;
  contract_status: string;
  contract_doc: string | null;
  pricing_model: number;
  fixed_price: string;
  zid_percentage: string;
  partner_percentage: string;
  vat_percentage: string;
  activation_fees: string;
  slaps_details?: SlapDetails[];
};

export type ThemeListType = {
  list: ThemeType[];
};

export type ThemeType = {
  theme_id: number;
  theme_name: string;
  pricing_model: number;
  fixed_price: string;
  zid_percentage: string;
  partner_percentage: string;
  contract_status: string;
  contract_doc: string | null;
  last_upload_contract_date: string | null;
  created_at: string | null;
  publish_status: ThemePublishStatusEnum | '';
};

export enum ThemePublishStatusEnum {
  Published = 'published',
  Draft = 'draft',
}

export enum ThemePublishStatusValuesEnum {
  Draft = 'Draft',
  Published = 'Published',
}

export type SlapDetails = {
  app_id: number;
  slap_number: number;
  orders: number;
  price: number;
  slap_key?: string;
  slap_value?: string;
};

export type PartnershipPartnerType = {
  id: number;
  name: string;
  email: string;
  is_independent: number;
  company_name: string;
  development_store: string;
};

export type PartnershipContractType = {
  company_name: string;
  company_website: string;
  company_work_field_type: number;
  company_work_field: number;
  customer_count: number;
  partnership_officer_email: string;
  partnership_officer_mobile: string;
  partnership_officer_name: string;
  company_profile_doc: string;
  commercial_register_doc: string;
  vat_certificate_doc: string | null;
  service_pricing_doc: string;
  communications_license_doc: string | null;
  submission_date: string;
};

export type PartnerBankAccountType = {
  bank_name: string | null;
  account_name: string | null;
  iban: string | null;
  swift_code: string | null;
  country: string | null;
  city: string | null;
};
