<template>
  <fieldset class="mb-3 md:mb-5">
    <label>
      <base-text textStyle="h5" classes="flex mb-1"> {{ label }} </base-text>
    </label>
    <zid-input :value="value" :disabled="true"> </zid-input>
  </fieldset>
</template>

<script>
import BaseText from '@/components/text/BaseText.vue';
import { ZidInput } from '@zidsa/ui';
export default {
  name: 'DisabledInputField',
  components: {
    BaseText,
    ZidInput,
  },
  props: {
    label: String,
    value: String,
  },
};
</script>
