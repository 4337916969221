import BaseModel from '@/api/models/BaseModel';
import {
  FinancePartnersManagementPaginationInterface,
  FinancePartnersManagementPartnerInterface,
} from '@/domains/finance-admin/types/partners-management.type';

class FinancePartnersManagementResponseModel extends BaseModel {
  public partners: FinancePartnersManagementPartnerInterface[];
  public pagination: FinancePartnersManagementPaginationInterface;

  constructor(args: any) {
    super(args);
    this.partners = args.partners;
    this.pagination = args.pagination;
  }
}

export default FinancePartnersManagementResponseModel;
