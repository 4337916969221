import { defineComponent } from '@vue/composition-api';
import BaseText from '@/components/text/BaseText.vue';
import BaseIcon from '@/components/base-icon/BaseIcon.vue';
import DisabledTextInput from './partner/DisabledTextInput.vue';
import FileDownloadLink from './partner/FileDownloadLink.vue';
import usePartnerDetails from '@/domains/finance-admin/helpers/usePartnerDetails';
import {
  ZidLoader,
  ZidBreadcrumb,
  ZidBreadcrumbItem,
  ZidInput,
  ZidSelect,
  ZidSelectOption,
  ZidSelectHeader,
  ZidSelectBody,
  ZidTable,
  ZidTableRowGroup,
  ZidTableHeader,
  ZidTableRow,
  ZidTableCell,
} from '@zidsa/ui';
import dayjs from 'dayjs';

export default defineComponent({
  components: {
    ZidLoader,
    ZidBreadcrumb,
    ZidBreadcrumbItem,
    ZidInput,
    ZidSelect,
    ZidSelectHeader,
    ZidSelectBody,
    ZidSelectOption,
    BaseText,
    BaseIcon,
    DisabledTextInput,
    FileDownloadLink,
    ZidTable,
    ZidTableRowGroup,
    ZidTableHeader,
    ZidTableRow,
    ZidTableCell,
  },
  setup(_, context) {
    return {
      ...usePartnerDetails(parseInt(context.root.$route.params.id)),
      dayjs,
    };
  },
});
