import httpClient from '@/api/httpClient';
import BaseModel from '@/api/models/BaseModel';
import ErrorModel from '@/api/models/ErrorModel';
import ThemesFinanceResponseModel from '../models/ThemesFinanceResponseModel';
import {
  ThemesManagementConfirmModalActionsEnum,
  ThemesManagementFilterParamsInterface,
  ThemesManagementPaymentStatusEnum,
} from '../types/themes-management.type';

const ENDPOINT_URL = '/market/finance-admin';

export const getAllPurchases = (
  params: ThemesManagementFilterParamsInterface,
): Promise<ThemesFinanceResponseModel | ErrorModel> => {
  const searchParams: ThemesManagementFilterParamsInterface = {
    ...(params.search && { search: params.search }),
    ...(params.theme && { theme: params.theme }),
    ...(params.partner && { partner: params.partner }),
    ...(params.status && params.status != ThemesManagementPaymentStatusEnum.All && { status: params.status }),
    ...(params.purchased_from && { purchased_from: params.purchased_from }),
    ...(params.purchased_to && { purchased_to: params.purchased_to }),
    ...(params.per_page && { per_page: params.per_page }),
    ...(params.page && { page: params.page }),
  };

  return httpClient
    .get(`${ENDPOINT_URL}/finance/themes/payment-status`, { params: { ...searchParams } })
    .then((response) => new ThemesFinanceResponseModel(response))
    .catch((error) => error);
};

export const updateThemePurchasePaymentStatus = (
  ids: number[] | null,
  status: ThemesManagementConfirmModalActionsEnum | null,
): Promise<BaseModel | ErrorModel> => {
  return httpClient
    .put(`${ENDPOINT_URL}/finance/themes/payment-status`, {
      ids: ids,
      status: status,
    })
    .then((response) => new BaseModel(response))
    .catch((error) => error);
};
