import Vue from 'vue';
import { computed, onBeforeMount, reactive, ref, watch } from '@vue/composition-api';
import { getPartnershipDetails } from '@/api/top-level-apis/partnership/partnership';
import {
  PartnersManagementRoutesEnum,
  WorkFieldEnum,
  WorkFieldValueEnum,
  WorkSubFieldEnum,
  WorkSubFieldValueEnum,
  PotentialCustomersValueEnum,
  PotentialCustomersLabelEnum,
  RevenueModelEnum,
  RevenueModelValueEnum,
  ApplicationTypeEnum,
  ApplicationTypeLabelEnum,
  ApplicationListingStatusEnum,
  ApplicationListingStatusLabelEnum,
  AppType,
  AppListType,
  FinancePartnerDetailsDropdownAppType,
  PartnershipContractType,
  PartnerBankAccountType,
  PartnershipPartnerType,
  FinancePartnersManagementNumericFieldType,
  ThemeListType,
  ThemeType,
  ThemePublishStatusEnum,
  ThemePublishStatusValuesEnum,
  FinancePartnerDetailsDropdownThemeType,
} from '@/domains/finance-admin/types/partner-details.types';
import ErrorModel from '@/api/models/ErrorModel';

const usePartnerDetails = (partnerId: number) => {
  const EMPTY_APP_INFO: AppType = {
    app_id: 0,
    partner_id: 0,
    app_name: '',
    status: 0,
    type: 0,
    icon: '',
    contract_status: '',
    contract_doc: null,
    pricing_model: 0,
    fixed_price: '',
    zid_percentage: '',
    partner_percentage: '',
    vat_percentage: '',
    activation_fees: '',
  };
  const EMPTY_THEME_INFO: ThemeType = {
    theme_id: 0,
    theme_name: '',
    pricing_model: 0,
    fixed_price: '',
    zid_percentage: '',
    partner_percentage: '',
    contract_status: '',
    contract_doc: null,
    last_upload_contract_date: null,
    created_at: '',
    publish_status: '',
  };
  const isLoadingPartnerDetails = ref(false);
  const selectedApp = reactive<FinancePartnersManagementNumericFieldType>({ label: '', value: 0 });
  const selectedTheme = reactive<FinancePartnersManagementNumericFieldType>({ label: '', value: 0 });
  const allAppsList = reactive<AppListType>({ list: [] });
  const allThemesList = reactive<ThemeListType>({ list: [] });
  const appSelectionList = reactive<FinancePartnerDetailsDropdownAppType>({ list: [] });
  const themeSelectionList = reactive<FinancePartnerDetailsDropdownThemeType>({ list: [] });
  const partnershipInfo = reactive<PartnershipContractType>({
    company_name: '',
    company_website: '',
    company_work_field_type: 0,
    company_work_field: 0,
    customer_count: 0,
    partnership_officer_email: '',
    partnership_officer_mobile: '',
    partnership_officer_name: '',
    company_profile_doc: '',
    commercial_register_doc: '',
    vat_certificate_doc: '',
    service_pricing_doc: '',
    communications_license_doc: '',
    submission_date: '',
  });
  const bankAccountInfo = reactive<PartnerBankAccountType>({
    bank_name: null,
    account_name: null,
    iban: null,
    swift_code: null,
    country: null,
    city: null,
  });
  const selectedAppInfo = reactive<AppType>(EMPTY_APP_INFO);
  const selectedThemeInfo = reactive<ThemeType>(EMPTY_THEME_INFO);
  const partnerInfo = reactive<PartnershipPartnerType>({
    id: 0,
    name: '',
    email: '',
    is_independent: 0,
    company_name: '',
    development_store: '',
  });
  const companyWorkField = computed(() => {
    return partnershipInfo.company_work_field_type == WorkFieldValueEnum.DeliveryOrStorage
      ? WorkFieldEnum.DeliveryOrStorage
      : WorkFieldEnum.TechnologySolutions;
  });
  const companyWorkSubField = computed(() => {
    switch (partnershipInfo.company_work_field) {
      case WorkSubFieldValueEnum.AccountingSoftwareProvider:
        return WorkSubFieldEnum.AccountingSoftwareProvider;
      case WorkSubFieldValueEnum.ChatCommunicationSolutionsProvider:
        return WorkSubFieldEnum.ChatCommunicationSolutionsProvider;
      case WorkSubFieldValueEnum.DedicatedApplicationsForZidMerchants:
        return WorkSubFieldEnum.DedicatedApplicationsForZidMerchants;
      case WorkSubFieldValueEnum.LastMileDelivery:
        return WorkSubFieldEnum.LastMileDelivery;
      case WorkSubFieldValueEnum.OtherSolutions:
        return WorkSubFieldEnum.OtherSolutions;
      case WorkSubFieldValueEnum.ResourceManagementSoftwareProvider:
        return WorkSubFieldEnum.ResourceManagementSoftwareProvider;
      case WorkSubFieldValueEnum.StorageAndOrderProcessing:
        return WorkSubFieldEnum.StorageAndOrderProcessing;
      default:
        return WorkSubFieldEnum.All;
    }
  });
  const potentialCustomersLabel = computed(() => {
    switch (partnershipInfo.customer_count) {
      case PotentialCustomersValueEnum['0-50']:
        return PotentialCustomersLabelEnum['0-50'];
      case PotentialCustomersValueEnum['51-100']:
        return PotentialCustomersLabelEnum['51-100'];
      case PotentialCustomersValueEnum['101-500']:
        return PotentialCustomersLabelEnum['101-500'];
      case PotentialCustomersValueEnum.MoreThan500:
        return PotentialCustomersLabelEnum.MoreThan500;
      default:
        return '';
    }
  });
  const revenueModelLabel = computed(() => {
    if (selectedAppInfo.app_id !== 0) {
      switch (selectedAppInfo.pricing_model) {
        case RevenueModelValueEnum.FixedPrice:
          return RevenueModelEnum.FixedPrice;
        case RevenueModelValueEnum.PercentageOfRevenue:
          return RevenueModelEnum.PercentageOfRevenue;
        case RevenueModelValueEnum.Slap:
          return RevenueModelEnum.Slap;
        default:
          return '';
      }
    } else if (selectedThemeInfo.theme_id !== 0) {
      switch (selectedThemeInfo.pricing_model) {
        case RevenueModelValueEnum.FixedPrice:
          return RevenueModelEnum.FixedPrice;
        case RevenueModelValueEnum.PercentageOfRevenue:
          return RevenueModelEnum.PercentageOfRevenue;
        case RevenueModelValueEnum.Slap:
          return RevenueModelEnum.Slap;
        default:
          return '';
      }
    } else return '';
  });

  const vatInclude = computed(() => {
    return parseFloat(selectedAppInfo.vat_percentage) > 0;
  });

  const selectedAppType = computed(() => {
    return getAppTypeLabel(selectedAppInfo.type);
  });

  onBeforeMount(async () => {
    await loadPartnerDetails();
  });

  watch(
    selectedApp,
    () => {
      if (selectedApp.value) {
        fillSelectedAppInfo();
        resetSelectedThemeInfo();
        selectedTheme.label = '';
        selectedTheme.value = 0;
      }
    },
    { deep: true },
  );

  watch(
    selectedTheme,
    () => {
      if (selectedTheme.value) {
        fillSelectedThemeInfo();
        resetSelectedAppInfo();
        selectedApp.label = '';
        selectedApp.value = 0;
      }
    },
    { deep: true },
  );

  const isAppSelected = computed(() => selectedApp.value);

  const loadPartnerDetails = async () => {
    if (Number.isNaN(partnerId)) {
      Vue.$toast.error('Invalid partner Id supplied!');
      return;
    }
    isLoadingPartnerDetails.value = true;
    const response = await getPartnershipDetails(partnerId);

    if (response instanceof ErrorModel || !response.partner_contract) {
      Vue.$toast.error(`Cannot load partner's details`);
      isLoadingPartnerDetails.value = false;
      return;
    }
    allAppsList.list = response.partner_contract.apps;
    allThemesList.list = response.partner_contract.themes;

    appSelectionList.list = createAppSelectionList(allAppsList.list);
    if (appSelectionList.list.length) {
      selectedApp.label = appSelectionList.list[0].label;
      selectedApp.value = appSelectionList.list[0].value;
    }

    themeSelectionList.list = createThemeSelectionList(allThemesList.list);

    fillPartnershipInfo(response.partner_contract.partnership_details);
    partnerInfo.id = response.partner_contract.partner.id;
    partnerInfo.name = response.partner_contract.partner.name;
    partnerInfo.email = response.partner_contract.partner.email;
    partnerInfo.is_independent = response.partner_contract.partner.is_independent;
    partnerInfo.development_store = response.partner_contract.partner.development_store;
    partnerInfo.company_name = response.partner_contract.partner.company_name;
    bankAccountInfo.bank_name = response.partner_contract.partner_bank_account.bank_name;
    bankAccountInfo.account_name = response.partner_contract.partner_bank_account.account_name;
    bankAccountInfo.iban = response.partner_contract.partner_bank_account.iban;
    bankAccountInfo.swift_code = response.partner_contract.partner_bank_account.swift_code;
    bankAccountInfo.city = response.partner_contract.partner_bank_account.city;
    bankAccountInfo.country = response.partner_contract.partner_bank_account.country;
    isLoadingPartnerDetails.value = false;
  };

  const getOnlyPublishedApps = (apps: AppType[]) => {
    return apps.filter((app) => {
      return app.status === ApplicationListingStatusEnum.Published;
    });
  };

  const createAppSelectionList = (apps: AppType[]) => {
    return getOnlyPublishedApps(apps).map((app) => {
      return { label: app.app_name, value: app.app_id };
    });
  };

  const getOnlyPublishedThemes = (themes: ThemeType[]) => {
    return themes.filter((theme) => {
      return theme.publish_status === ThemePublishStatusEnum.Published;
    });
  };

  const createThemeSelectionList = (themes: ThemeType[]) => {
    return getOnlyPublishedThemes(themes).map((theme) => {
      return { label: theme.theme_name, value: theme.theme_id };
    });
  };

  const fillPartnershipInfo = (info: PartnershipContractType) => {
    partnershipInfo.company_name = info.company_name;
    partnershipInfo.company_website = info.company_website;
    partnershipInfo.company_work_field_type = info.company_work_field_type;
    partnershipInfo.company_work_field = info.company_work_field;
    partnershipInfo.customer_count = info.customer_count;
    partnershipInfo.partnership_officer_email = info.partnership_officer_email;
    partnershipInfo.partnership_officer_mobile = info.partnership_officer_mobile;
    partnershipInfo.partnership_officer_name = info.partnership_officer_name;
    partnershipInfo.company_profile_doc = info.company_profile_doc;
    partnershipInfo.commercial_register_doc = info.commercial_register_doc;
    partnershipInfo.vat_certificate_doc = info.vat_certificate_doc;
    partnershipInfo.service_pricing_doc = info.service_pricing_doc;
    partnershipInfo.communications_license_doc = info.communications_license_doc;
    partnershipInfo.submission_date = info.submission_date;
  };

  const fillSelectedAppInfo = () => {
    const appInfo = allAppsList.list.find((app) => {
      return app.app_id === selectedApp.value;
    });
    if (appInfo) {
      selectedAppInfo.app_id = appInfo.app_id;
      selectedAppInfo.partner_id = appInfo.partner_id;
      selectedAppInfo.app_name = appInfo.app_name;
      selectedAppInfo.status = appInfo.status;
      selectedAppInfo.type = appInfo.type;
      selectedAppInfo.icon = appInfo.icon;
      selectedAppInfo.contract_status = appInfo.contract_status;
      selectedAppInfo.contract_doc = appInfo.contract_doc;
      selectedAppInfo.pricing_model = appInfo.pricing_model;
      selectedAppInfo.fixed_price = appInfo.fixed_price;
      selectedAppInfo.zid_percentage = appInfo.zid_percentage;
      selectedAppInfo.partner_percentage = appInfo.partner_percentage;
      selectedAppInfo.vat_percentage = appInfo.vat_percentage;
      selectedAppInfo.activation_fees = appInfo.activation_fees;
      selectedAppInfo.slaps_details = appInfo.slaps_details;
    }
  };

  const resetSelectedAppInfo = () => {
    selectedAppInfo.app_id = 0;
    selectedAppInfo.partner_id = 0;
    selectedAppInfo.app_name = '';
    selectedAppInfo.status = 0;
    selectedAppInfo.type = 0;
    selectedAppInfo.icon = '';
    selectedAppInfo.contract_status = '';
    selectedAppInfo.contract_doc = null;
    selectedAppInfo.pricing_model = 0;
    selectedAppInfo.fixed_price = '';
    selectedAppInfo.zid_percentage = '';
    selectedAppInfo.partner_percentage = '';
    selectedAppInfo.vat_percentage = '';
    selectedAppInfo.activation_fees = '';
  };

  const fillSelectedThemeInfo = () => {
    const themeInfo = allThemesList.list.find((theme) => {
      return theme.theme_id === selectedTheme.value;
    });
    if (themeInfo) {
      selectedThemeInfo.theme_id = themeInfo.theme_id;
      selectedThemeInfo.theme_name = themeInfo.theme_name;
      selectedThemeInfo.contract_status = themeInfo.contract_status;
      selectedThemeInfo.contract_doc = themeInfo.contract_doc;
      selectedThemeInfo.pricing_model = themeInfo.pricing_model;
      selectedThemeInfo.fixed_price = themeInfo.fixed_price;
      selectedThemeInfo.zid_percentage = themeInfo.zid_percentage;
      selectedThemeInfo.partner_percentage = themeInfo.partner_percentage;
      selectedThemeInfo.publish_status = themeInfo.publish_status;
    }
  };

  const resetSelectedThemeInfo = () => {
    selectedThemeInfo.theme_id = 0;
    selectedThemeInfo.theme_name = '';
    selectedThemeInfo.pricing_model = 0;
    selectedThemeInfo.fixed_price = '';
    selectedThemeInfo.zid_percentage = '';
    selectedThemeInfo.partner_percentage = '';
    selectedThemeInfo.contract_status = '';
    selectedThemeInfo.contract_doc = null;
    selectedThemeInfo.last_upload_contract_date = null;
    selectedThemeInfo.created_at = '';
    selectedThemeInfo.publish_status = '';
  };

  const getAppTypeLabel = (appType: number) => {
    switch (appType) {
      case ApplicationTypeEnum.OAuth:
        return ApplicationTypeLabelEnum.OAuth;
      case ApplicationTypeEnum.Javascript:
        return ApplicationTypeLabelEnum.Javascript;
      case ApplicationTypeEnum.Shipping:
        return ApplicationTypeLabelEnum.Shipping;
      default:
        return '';
    }
  };

  const getAppStatusLabel = (appStatus: number | null) => {
    switch (appStatus) {
      case ApplicationListingStatusEnum.RequestToPublish:
        return ApplicationListingStatusLabelEnum.RequestToPublish;
      case ApplicationListingStatusEnum.Rejected:
        return ApplicationListingStatusLabelEnum.Rejected;
      case ApplicationListingStatusEnum.Published:
        return ApplicationListingStatusLabelEnum.Published;
      case ApplicationListingStatusEnum.Unpublished:
        return ApplicationListingStatusLabelEnum.Unpublished;
      default:
        return ApplicationListingStatusLabelEnum.Draft;
    }
  };

  const getThemeStatusLabel = (themeStatus: ThemePublishStatusEnum | null) => {
    switch (themeStatus) {
      case ThemePublishStatusEnum.Published:
        return ThemePublishStatusValuesEnum.Published;
      case ThemePublishStatusEnum.Draft:
        return ThemePublishStatusValuesEnum.Draft;
      default:
        return ThemePublishStatusValuesEnum.Draft;
    }
  };

  const onSelectedAppChange = (event: FinancePartnersManagementNumericFieldType) => {
    selectedApp.label = event.label;
    selectedApp.value = event.value;
  };

  const onSelectedThemeChange = (event: FinancePartnersManagementNumericFieldType) => {
    selectedTheme.label = event.label;
    selectedTheme.value = event.value;
  };

  return {
    isLoadingPartnerDetails,
    PartnersManagementRoutesEnum,
    RevenueModelValueEnum,
    WorkFieldEnum,
    appSelectionList,
    themeSelectionList,
    allAppsList,
    allThemesList,
    selectedApp,
    selectedTheme,
    partnershipInfo,
    partnerInfo,
    companyWorkField,
    companyWorkSubField,
    bankAccountInfo,
    potentialCustomersLabel,
    selectedAppInfo,
    selectedThemeInfo,
    revenueModelLabel,
    vatInclude,
    selectedAppType,
    ApplicationListingStatusEnum,
    ThemePublishStatusEnum,
    isAppSelected,
    getAppTypeLabel,
    getAppStatusLabel,
    getThemeStatusLabel,
    onSelectedAppChange,
    onSelectedThemeChange,
  };
};

export default usePartnerDetails;
