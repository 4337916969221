export enum FinancePartnersManagementOrderByEnum {
  TotalApps = 'total_apps',
  AppInstalls = 'total_app_installed',
}
export enum FinancePartnersManagementOrderDirEnum {
  Ascending = 'asc',
  Descending = 'desc',
}

export type FinancePartnersManagementNumericFieldType = {
  label: string;
  value: number;
};

export type FinancePartnersManagementFiltersState = {
  search: string;
  resultPerPage: FinancePartnersManagementNumericFieldType;
  orderBy: FinancePartnersManagementOrderByEnum;
  orderDir: FinancePartnersManagementOrderDirEnum;
  targetPage: number;
};

export type FinancePartnersManagementFilterParamsType = {
  search: string;
  per_page: number;
  page: number;
  order_by: string;
  order_dir: string;
};
export type FinancePartnersListType = { list: FinancePartnersManagementPartnerInterface[] };
export interface FinancePartnersManagementFilterParamsInterface {
  search?: string;
  per_page?: number;
  page?: number;
  order_by?: string;
  order_dir?: string;
}

export interface FinancePartnersManagementPartnerInterface {
  id: number;
  name: string;
  is_independent: number;
  email: string;
  total_apps: number;
  total_app_installed: number;
  total_themes: number;
}

export interface FinancePartnersManagementPaginationInterface {
  last_page: number;
  current_page: number;
}
