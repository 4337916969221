import { defineComponent } from '@vue/composition-api';
import BaseText from '@/components/text/BaseText.vue';
import BaseIcon from '@/components/base-icon/BaseIcon.vue';
import SearchIcon from '@/assets/icons/applications/SearchIcon.vue';
import EyeIcon from '@/assets/icons/domains/finance-admin/EyeIcon.vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import {
  ZidButton,
  ZidInput,
  ZidLoader,
  ZidPagination,
  ZidSelect,
  ZidSelectBody,
  ZidSelectHeader,
  ZidSelectOption,
  ZidTable,
  ZidTableRowGroup,
  ZidTableHeader,
  ZidTableRow,
  ZidTableCell,
} from '@zidsa/ui';
import usePartnersManagement from '@/domains/finance-admin/helpers/usePartnersManagement';

export default defineComponent({
  components: {
    BaseText,
    BaseIcon,
    SearchIcon,
    EyeIcon,
    FontAwesomeIcon,
    ZidInput,
    ZidSelect,
    ZidSelectHeader,
    ZidSelectBody,
    ZidSelectOption,
    ZidButton,
    ZidLoader,
    ZidPagination,
    ZidTable,
    ZidTableRowGroup,
    ZidTableHeader,
    ZidTableRow,
    ZidTableCell,
  },
  setup() {
    return {
      ...usePartnersManagement(),
    };
  },
});
